if($('.header--fixed').length){

    const navbar = document.getElementsByClassName("header--fixed");
    const sticky = navbar[0].offsetTop;
    stickyHeader(navbar, sticky);
    window.onscroll = function() {stickyHeader(navbar, sticky)};
}
function stickyHeader(navbar, sticky) {
    
    if (window.pageYOffset > sticky) {
        navbar[0].classList.add("sticky")
    } else {
        navbar[0].classList.remove("sticky");
    }
}